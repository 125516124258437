<template>
  <div class="table_container">
    <h3 class="mb-7">
      Récapitulatif des commandes entre le
      {{ dateFromTimestam(fromTimestamp) }} et le
      {{ dateFromTimestam(toTimestamp) }}
    </h3>

    <table class="minimalistBlack mt-2" style="table-layout: fixed;">
      <thead>
        <tr>
          <th id="ref_col" class="ref_print_width ref_screen_width">
            Référence
          </th>
          <th class="print_1_5 basic-width">Quantité</th>
          <th class="print_6 screen_600">Désignation</th>
          <th class="print_1_5 basic-width">P.Achat</th>
          <th class="print_1_5 basic-width">P.Achat Total</th>
          <th class="print_1_5 basic-width">P.U</th>
          <th class="print_2 basic-width">Total HT</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(product, i) of products" :key="i">
          <td id="ref_col_td">
            {{ product.reference }}
            <v-btn
              text
              class="hide_on_print"
              @click.stop="showProductDetails(product)"
              ><Magnify
            /></v-btn>
          </td>

          <td class="ui-helper-center">{{ product.quantity }}</td>

          <td>
            <span>{{ product.designation }} </span>
            <span v-if="product.reference != 'DIV'">
              <span style="font-size: 11px"
                >({{ product.dimensions }})
              </span>
              <span v-if="product.marque || product.gamme" style="font-weight: bold;"> | </span>
              <span >{{ product.marque }} </span>
              <span>{{ product.gamme }} </span>
            </span>
          </td>
          <td class="ui-helper-center">{{ toFixed2(product.prixAchat) }}</td>
          <td class="ui-helper-center">
            {{ toFixed2(product.prixAchat * product.quantity) }}
          </td>
          <td class="ui-helper-center">
            {{ toFixed2(product.prixA) }}
          </td>
          <td class="ui-helper-center">
            {{ toFixed2(product.prixA * product.quantity) }}
          </td>
        </tr>
      </tbody>
    </table>
    <p style="text-align: right" class="mr-7 mt-2">
      Total:
      {{
        products
          .map((x) => safeMultiply(x.quantity, x.prixA))
          .reduce((a, b) => safeAdd(a, b), 0)
      }}
      €
    </p>
    <v-dialog v-model="detailsDialog">
      <DetailsProductComponent
        v-if="selectedProductForDetails"
        :product="selectedProductForDetails"
      />
    </v-dialog>
    <br />
    <div class="pagebreak"></div>
    <!-- nouvelle page -->
    <h3 class="mb-7">
      Récapitulatif des commandes entre le
      {{ dateFromTimestam(fromTimestamp) }} et le
      {{ dateFromTimestam(toTimestamp) }}
    </h3>
    <p>Nombre total de commandes: {{ordersCount}}</p>
    <p>Nombre de refs vendues: {{products.length}}</p>
    <p>Nombre d'articles vendus: {{products.map(x => x.quantity).reduce((a,b) => a+b,0)}}</p> 
    <p>Total prix de ventes HT: {{products.map(x => x.quantity * x.prixA).reduce((a,b) => a+b, 0).toFixed(2)}} €</p>
    <p>Total prix de ventes TTC: {{products.map(x => x.quantity * x.prixA * 1.2).reduce((a,b) => a+b, 0).toFixed(2)}} €</p>
    <p>Différence total prix de ventes TTC - HT: {{(products.map(x => x.quantity * x.prixA * 1.2).reduce((a,b) => a+b, 0) - products.map(x => x.quantity * x.prixA).reduce((a,b) => a+b, 0)).toFixed(2)}} €</p>

    <p>Total prix Achat HT: {{products.map(x => x.quantity* x.prixAchat).reduce((a,b) => a+b, 0).toFixed(2)}} €</p>
    <p>Total prix Achat TTC: {{products.map(x => x.quantity* x.prixAchat * 1.2).reduce((a,b) => a+b, 0).toFixed(2)}} €</p>
    <p>Différence total prix Achat TTC - HT: {{(products.map(x => x.quantity* x.prixAchat * 1.2).reduce((a,b) => a+b, 0) - products.map(x => x.quantity* x.prixAchat).reduce((a,b) => a+b, 0)).toFixed(2)}} €</p>
    
    <p>Marge: {{products.map(x => x.quantity * x.prixA - x.quantity * x.prixAchat*1.2).reduce((a,b) => a+b, 0).toFixed(2)}} €</p>
    <p>Taux de Marge: {{(products.map(x => (x.quantity * x.prixA - x.quantity * x.prixAchat*1.2)).reduce((a,b) => a+b, 0)/products.map(x => x.quantity* x.prixAchat*1.2).reduce((a,b) => a+b, 0)*100).toFixed(2)}} %</p>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import DetailsProductComponent from "../components/DetailsProductComponent";
import Magnify from "vue-material-design-icons/Magnify.vue";
import axios from "axios";
import bigDecimal from "js-big-decimal";
export default {
  props: ["fromTimestamp", "toTimestamp"],
  data: () => ({
    newRef: "",
    selectedProductForDetails: null,
    products: [],
    client: "",
    ordersCount: 0
  }),
  components: {
    DetailsProductComponent,
    Magnify,
  },
  methods: {
    ...mapActions(["saveOrder"]),
    ...mapMutations(["setProductDetailsDialog", "addMessageAxios"]),
    focusNewRefInput() {
      let element = document.getElementById("ref_input");
      if (element != null) element.focus();
    },
    showProductDetails(product) {
      console.log(product.designation);
      this.selectedProductForDetails = product;
      this.setProductDetailsDialog(true);
    },
    async fetchOrdersCount() {
      try {
        let ordersCountRes = await axios.get(
          `${this.apiURL}/orders/nbOrdersBetween/${this.fromTimestamp}/${this.toTimestamp}}`
        );
        this.ordersCount = ordersCountRes.data.count
      } catch (err) {
        //console.log(err)
        this.addMessageAxios({ message: err.response.data, isError: true });
      }
    },
    async fetchCommandeDetails() {
      try {
        let orderRes = await axios.get(
          `${this.apiURL}/admin/orderWithItemsBetween/${this.fromTimestamp}/${this.toTimestamp}}`
        );
        //console.log(orderRes.data)
        //this.products = orderRes.data.LocalOrderItems || []

        for (let orderItem of orderRes.data) {
          //console.log(orderItem)
          this.addProduct(orderItem);
        }
        this.client = orderRes.data.user || "";
      } catch (err) {
        //console.log(err)
        this.addMessageAxios({ message: err.response.data, isError: true });
      }
    },
    dateFromTimestam(timestamp) {
      console.log("getting date from timestam ->", timestamp);
      var dateFromT = new Date(timestamp * 1);
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var date = dateFromT.toLocaleDateString("fr-FR", options);
      console.log("got date ->", date);
      return date;
    },
    safeMultiply(a, b) {
      //console.log(bigDecimal.multiply(a,b))
      return bigDecimal.multiply(a, b) * 1;
    },
    safeAdd(a, b) {
      return bigDecimal.add(a, b) * 1;
    },
    addProduct(orderItem) {
      let prod = orderItem.product;
      prod.commentaire = orderItem.commentaire;
      prod.tva = 20.0;
      prod.quantity = orderItem.quantity;
      prod.prixUnitaire_tmp = orderItem.currentPrice;
      prod.montantNet = this.safeMultiply(
        prod.prixA,
        orderItem.quantity
      );
      this.products.push(prod);
    },
    toFixed2(x) {
      try {
        return x.toFixed(2);
      } catch (e) {
        console.log(e);
        return x;
      }
    },
  },
  computed: {
    ...mapGetters([
      "erreurAjoutProduitFacture",
      "productDetailsDialog",
      "apiURL",
    ]),
    detailsDialog: {
      get: function () {
        return this.productDetailsDialog;
      },
      set: function (bool) {
        this.setProductDetailsDialog(bool);
      },
    },
  },
  watch: {
    products() {
      //let newRef = maj.slice(-1)[0]
      //console.log(maj, ancien, ancien.length)
      //console.log('uniteDeVente_'+newRef.reference)
      console.log("uniteDeVente_" + (this.products.length - 1));
      setTimeout(() => {
        let element = document.getElementById(
          "uniteDeVente_" + (this.products.length - 1)
        );
        if (element != null) element.focus();
      }, 0);
    },
    productDetailsDialog(val) {
      if (!val) this.selectedProductForDetails = null;
    },
  },
  updated() {},
  mounted() {
    console.log('mounted recap periode')
    this.fetchCommandeDetails();
    this.fetchOrdersCount();
  },
};
</script>

<style scoped>
@media screen {
  .basic-width {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
  }
  .hide_on_screen {
    display: none;
  }
  .totalCommande {
    position: fixed;
    bottom: 3em;
    right: 3em;
  }
  input {
    /*border-bottom: 0.5mm solid #00000033;*/
    transition: all 0.5s;
  }
  .v-card {
    overflow: auto;
  }
  .width_1_5 {
    width: 1.5cm;
  }
  .ref_input {
    /*width: 3.1cm;*/
  }
  .ref_screen_width {
    width: 180px;
  }
  .screen_600 {
    width: 600px;
  }
  table.minimalistBlack {
    border: 1px solid #000000;
    text-align: left;
    border-collapse: collapse;
    width: 100%;
  }
  table.minimalistBlack td,
  table.minimalistBlack th {
    border: 0.5mm solid #000000;
    padding: 5px 4px;
  }
  table.minimalistBlack tbody td {
    font-size: 14px;
    text-align: center;
  }
  table.minimalistBlack thead {
    border: 0.5mm solid #000000;
  }
  table.minimalistBlack thead th {
    font-size: 16px;
    font-weight: bold;
    color: rgb(238, 97, 97);
    text-align: center;
  }
  #ref_col {
    /*text-align: left;*/
  }
  #ref_col_td {
    text-align: right !important;
  }
  table.minimalistBlack tfoot {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    border: 1px solid #000000;
  }
  table.minimalistBlack tfoot td {
    /*font-size: 14px;*/
    border: 0.5mm solid black;
  }
}

@media print {
  .ui-helper-center {
    text-align: center;
  }
  .table_container {
    padding-left: 0.7cm;
  }
  .ref_print_width {
    width: 3cm;
  }
  .hide_on_print {
    display: none;
  }
  .print_1_5 {
    width: 1.5cm;
  }
  .print_2 {
    width: 2cm;
  }
  .print_6 {
    width: 9cm;
  }

  .width_1_5 {
    width: 1.5cm;
  }
  .ref_input {
    width: 3.1cm;
  }
  table.minimalistBlack {
    width: 20cm;
    border: 1px solid #000000;
    text-align: left;
    border-collapse: collapse;
  }
  table.minimalistBlack td,
  table.minimalistBlack th {
    border: 0.5mm solid #000000;
  }
  table.minimalistBlack tbody td {
    font-size: 14px;
  }
  table.minimalistBlack thead {
    border-bottom: 0.5mm solid #000000;
  }
  table.minimalistBlack thead th {
    font-size: 15px;
    font-weight: bold;
    color: #000000;
    text-align: center;
  }
  #ref_col {
    text-align: left;
  }

  table.minimalistBlack tfoot {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    border-top: 1px solid #000000;
  }
  table.minimalistBlack tfoot td {
    /*font-size: 14px;*/
    border: 0.5mm solid black;
  }
  .pagebreak { page-break-before: always; } /* page-break-after works, as well */
}
</style>